<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/dashboard">
      <!-- <CIcon name="logo" height="48" alt="Logo"/> -->
      <img src="../assets/img/logo_biocloudaurum_color.png" class="c-sidebar-brand-full"  width="140">
    </CHeaderBrand>
    <a v-on:click="logout" class="for_mobile">
      <CIcon name="cil-account-logout" style="margin-top:1.2rem;"/>
    </a>

    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/">
          Home
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <CHeaderNavItem class="d-md-down-none mx-2" v-if="roleLogin !='1'">
        <CHeaderNavLink>
          <CIcon name="cib-btc"/> {{this.data_user.bitcoin_balance_wallet}}
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="d-md-down-none mx-2">
        <CHeaderNavLink>
          <router-link to="/account-setting">
            <CIcon name="cil-people"/>
          </router-link>
        </CHeaderNavLink>
      </CHeaderNavItem>
      <a v-on:click="logout">
        <CHeaderNavItem class="d-md-down-none mx-2">
          <CHeaderNavLink>
              <CIcon name="cil-account-logout"/>
          </CHeaderNavLink>
        </CHeaderNavItem>
      </a>
      <!-- <TheHeaderDropdownAccnt/> -->
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader>
  </CHeader>
</template>

<script>
import axios from 'axios';
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt
  },
  data(){
    return{
      roleLogin:localStorage.getItem('role'),
      urlApi:this.$store.state.urlApi,
      data_user:[]
    }
  },
  mounted(){
    axios.get(`${this.urlApi}/api/user-details/${localStorage.getItem('id')}`).then(response => {
      this.data_user = response.data
    })
  },
  methods:{
    logout(){
      localStorage.clear();
      location.href = ('/#/pages/login')
    }
  }
}
</script>
