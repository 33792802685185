let menu

if(localStorage.getItem('role') == 1){
  menu = [
    {
      _name: 'CSidebarNav',
      _children: [
        {
          _name: 'CSidebarNavItem',
          name: 'Dashboard',
          to: '/dashboard-admin',
          icon: 'cil-speedometer'
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Users',
          to: '/users',
          icon: 'cil-people'
        },
        {
          _name: 'CSidebarNavItem',
          name: 'Withdraw',
          to: '/withdraw',
          icon: 'cil-calendar-check'
        },
        {
          _name: 'CSidebarNavDivider',
          _class: 'm-2'
        }
      ]
    }
  ]
} else {
  menu = [
    {
      _name: 'CSidebarNav',
      _children: [
        {
          _name: 'CSidebarNavItem',
          name: 'Dashboard',
          to: '/dashboard',
          icon: 'cil-speedometer'
        },
        {
          _name: 'CSidebarNavDropdown',
          name: 'My Profile',
          route: '/profile',
          icon: 'cil-people',
          items: [
            {
              name: 'Account Setting',
              to: '/account-setting',
              // icon: 'cil-people',
            }
          ]
        },
        {
          _name: 'CSidebarNavDropdown',
          name: 'Order History',
          route: '/',
          icon: 'cil-basket',
          items: [
            {
              name: 'Mining Earning',
              to: '/mining-earning',
              // icon: 'cil-money',
            }
          ]
        },
        {
          _name: 'CSidebarNavDivider',
          _class: 'm-2'
        }
      ]
    }
  ]
}

export default menu